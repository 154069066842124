interface MenuItem {
  id: string
  name: string
  icon?: string
  state: string
  path: string
  children?: Array<MenuItem> | null
}

export const filterNaviMenu = function (
  menus?: Array<MenuItem>
): Array<string> {
  if (menus === undefined) return []
  const result: string[] = []

  function extractMenuName(menuList: Array<MenuItem>) {
    console.log(menuList)

    menuList.forEach((menu) => {
      result.push(menu.name)
      if (menu.children !== null && menu.children !== undefined) {
        extractMenuName(menu.children)
      }
    })
  }
  extractMenuName(menus)
  result.push('client.biz.code.accountUser.opening')
  // result.push('client.biz.code.settings.job')
  // result.push('client.biz.code.settings.AssignRoles')

  return result
}
