
import { Component, Vue } from 'vue-property-decorator'
import { SiteStateModule, SiteType } from '@/store/site_state'
import { EVENT_GLOBAL_GOTO_PAGE, PAGE_MAIN } from '@/event/event_types'
import { filterNaviMenu } from '@/utils/nav_util'
import { common } from '@/http/common'
@Component({
  name: 'PageEntryLogin'
})
export default class PageEntryLogin extends Vue {
  form: any
  loadingBtn = false

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'form' })
  }

  beforeMount() {
    SiteStateModule.regenerateVCode()
  }

  mounted() {
    this.getAppConfigInfo()
    document.addEventListener('keypress', this.enterKeyboard, true)
    this.$once('hook:beforeDestroy', () =>
      document.removeEventListener('keypress', this.enterKeyboard)
    )
  }

  get validateCodeImageUrl() {
    if (SiteStateModule.captcha === null) {
      return null
    } else {
      return '/api/hyper_fast/captcha.jpg?uuid=' + SiteStateModule.captcha
    }
  }

  enterKeyboard(event: KeyboardEvent) {
    if (event.ctrlKey && event.keyCode === 10) {
      this.login()
    }
  }

  async getAppConfigInfo() {
    let { data } = await this.$http.get(
      '/api/hyper_fast/sys/config/find/sys.config.code.platform.global.' +
        SiteStateModule.site
    )
    if (data.code === 0 && data.data && data.data.data) {
      let appConfigData = JSON.parse(data.data.data)
      console.log('getAppConfigInfo', appConfigData)
      sessionStorage.setItem(
        'account',
        common.extStringify(appConfigData.account, SiteStateModule.access_token)
      )

      sessionStorage.setItem(
        'isTokenSec',
        common.extStringify(appConfigData.isTokenSec)
      )
      console.debug('getAppConfigInfo', appConfigData)
    }
  }

  get siteLoginHeader() {
    if (SiteStateModule.site === SiteType.ADMIN) {
      return `${this.$t('client.biz.code.template')} ${this.$t(
        'page.login.title.port.admin'
      )}`
    } else if (SiteStateModule.site === SiteType.AGENT) {
      return `${this.$t('client.biz.code.template')} ${this.$t(
        'page.login.title.port.agent'
      )}`
    } else {
      return `${this.$t('client.biz.code.template')} ${this.$t(
        'page.login.title.port.client'
      )}`
    }
  }

  regenerateVcode() {
    SiteStateModule.regenerateVCode()
  }

  login() {
    this.form?.validateFields(async (err: Error, values: any) => {
      this.loadingBtn = true
      if (!err) {
        //启动就请求预备接口
        const { data } = await this.$http.post('/api/hyper_fast/sys/login', {
          username: values.username,
          password: values.password,
          captcha: values.validatorCode,
          uuid: SiteStateModule.captcha,
          type: this.siteType
        })
        if (data.code === 0 && data.data && data.data.token) {
          SiteStateModule.updateTokenInfo({
            token: data.data.token,
            expire: data.data.expire
          })
          //更新服务器时区
          SiteStateModule.updateServerTimeZone(data.data?.serverInfo?.timeZone)
          SiteStateModule.updateUserInfo(data.data.userinfo)
          //登录成功并得到菜单
          await this.getNaviList()
          this.$eventBus.$emit(EVENT_GLOBAL_GOTO_PAGE, PAGE_MAIN)
        } else {
          await this.regenerateVcode()
          this.loadingBtn = false
        }
      } else {
        this.loadingBtn = false
      }
    })
  }

  async getNaviList() {
    const { data } = await this.$http.get('/api/hyper_fast/sys/menu/nav')
    if (data && data.data) {
      // for (let index = 0; index < data.data.menus.length; index++) {
      //   const element = data.data.menus[index];

      // }

      SiteStateModule.updateSecMenuPerm({
        menus: filterNaviMenu(data.data.menus),
        menulist: data.data.menus,
        permissions: data.data.permissions
      })
    }
    this.loadingBtn = false
  }

  get siteType() {
    switch (SiteStateModule.site) {
      case 'admin':
        return 1
      case 'agent':
        return 2
      case 'client':
        return 3
      default:
        return 3
    }
  }
}
